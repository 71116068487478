<template>
    <div>
        <van-cell title="默认主就诊人:">{{showName}}</van-cell>
         <van-cell><span style="color: #57c4b7;">*温馨提示：您还可以绑定{{ind-dataList.length}}张健康卡，点击可查看及管理健康卡。如您发现功能异常或成员信息缺失，请清理微信缓存后重新进入。</span></van-cell>
        <div  v-for="(el,index) in dataList" :key="index" @click="intoDetailed(el)" style="width:300px;height:180px;margin:15px auto;position:relative;box-sizing:border-box;">
              <img :src="bgimg" alt="" width="100%" style="position:absolute;left:0;top:0;bottom:0;z=index:1;">
              <div style="position:absolute;top:15px;left:15px;right:15px;bottom:15px;">
                  <!-- 卡头 -->
                  <div>
                      <span style="float:left;font-size:11px;margin-top:5px; font-weight:bold;">云南省卫生健康委员会</span>
                      <div style="float:right;">
                          <van-icon :name="icon2" size="30" color="#1989fa" style="vertical-align:middle" />
                         <span style="margin-left:5px; font-weight:bold;">电子健康卡</span> 
                      </div>
                  </div>
                  <!-- 中间信息 -->
                  <div style="position:absolute;bottom:30px;left:0;right:0px">
                      <div style="float:left;">
                          <h1 style="margin-bottom:10px;margin-top:15px;">{{el.userName}}</h1>
                          <h1>{{el.idCard.substr(0,4)+'*********'+el.idCard.substr(14)}}</h1>
                      </div>
                      <div style="float:right;margin-right:5px;">
                          <vue-qr v-if="el.qrcode" :text="el.qrcode" :size="70" :margin="0"   :logoSrc="logo"  :logoScale="0.3" :correctLevel="0"></vue-qr>
                      </div>
                  </div> 
                    <!--底部  -->
                  <div style="position:absolute;bottom:5px;left:0;right:0;text-align:center;font-size:11px; font-weight:bold;">
                      中华人民共和国国家卫生健康委员会监制{{el.add}}</div>
              </div>
        </div>
         <van-button round style="width: 90%; height: auto; margin: 3% 5% 8% 5%; background: #57c4b7; color: white;" text="添加健康卡" @click="onclicaddcard()"/>
         <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"><span>&copy;拓实银医</span></div>
    </div>
</template>
<script>
import ajax from '../../lib/ajax';
import store from '../../store';
import { Dialog} from "vant";
export default {
    
    data(){
        return{
         bgimg:require("../../assets/imagel/cardnewbg.png"),
         icon2:require("../../assets/imagel/icon2.png"),
         logo:require("../../assets/imagel/logo_.png"),
         qrcode:require("../../assets/imagel/qrcode.png"),
         showIdCard:"",
         dataList:[],
         ind:5,
         showName:"",
        }
    },
    mounted(){
        this.onload();
    },
    methods:{
         onload(){
         let openid = store.getters["wechat/openid"];
        // alert(openid);
         ajax.get("/Api/CardService/WeChatGetMembers?input=" + openid)
        .then((res) => {
            console.log(res)
            res.data.result.forEach(el => {
                      let idcard=el.idCard;
                   let barCode=el.barCode;
                    ajax.get("/Api/HealthCardService/GetHealthQrCode?cardid="+barCode+"&idcard="+idcard+"&codetype=1")
                    .then((res)=>{  
                        this.$set(el,'qrcode',res.data.result.rsp.qrCodeText) 

                        })
                        
                if(el.isOwner){
                        this.dataList.unshift(el)
                        console.log("sssssss")
                        console.log(el.idCard)
                        store.commit('store/setOwneridcard',el.idCard);
                        this.showName=el.userName;
                }
                if(!el.isOwner){
                    this.dataList.push(el)
                }
                
              
                    
            });
            console.log(this.dataList)
        })
    },
    onclicaddcard(){
        if(this.dataList.length<=this.ind){
          this.$router.replace("/healcardM").catch((err)=>{console.log(err)})
        }else{
             Dialog.alert({
            title:'温馨提示',
            message:'当前绑定已达上限，如需继续添加，需解绑其它健康卡'
          }).then(() => {
              return;
          })
        }
        
    },
    intoDetailed(el){
                      store.commit("store/setUserId", el.userId);
                     store.commit('store/setUserName',el.userName);
                     store.commit('store/setIdcard',el.idCard);
                     store.commit('store/setPhone',el.phone);
                     store.commit('store/setHealthCardId',el.barCode);
        this.$router.replace("/HcCode").catch((err)=>{console.log(err)})
    }
    }
   
}
</script>
<style  scoped>

</style>